import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import CmsHeader from '../components/Shared/CmsHeader'
import Slider from '../components/Core/Slider'
import Text from '../components/Core/Text'
import DepartureIllus from '../components/Illus/DepartureIllus'
import EquipmentIllus from '../components/Illus/EquipmentIllus'
import FlowerIllus from '../components/Illus/FlowerIllus'
import HelpIllus from '../components/Illus/HelpIllus'
import RentIllus from '../components/Illus/RentIllus'
import ReservationIllus from '../components/Illus/ReservationIllus'
import FaqContent from '../components/Shared/FaqContent'
import * as styles from './styles/faq.module.scss'
import { graphql } from 'gatsby'
import { stripeHtmlTag } from '../utils/mixins'
import { Helmet } from 'react-helmet'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'

const icon = {
  reservation: <ReservationIllus />,
  help: <HelpIllus />,
  rent: <RentIllus />,
  equipment: <EquipmentIllus />,
  life: <FlowerIllus />,
  leaving: <DepartureIllus />,
}

export interface DataFaqItem {
  question: string
  answer: string
}

export interface DataFaq {
  icon: keyof typeof icon
  title: string
  questions: DataFaqItem[]
}

export interface DataFaqThemeBlock {
  title: string
  picto: keyof typeof icon
  pictoImage: {
    src: string
  }
  questions: [DataFaqItem]
}

export const Head = head('umbracoListingResidencePage', 'faq')

export default function faq({ data }: any) {
  const faqPage =
    data.allUmbracoFaqPage.nodes && data.allUmbracoFaqPage.nodes.length > 0
      ? data.allUmbracoFaqPage.nodes[0]
      : {}
  const themeBlocks = faqPage.themeBlocks || []
  const [currentData, setCurrentData] = useState<DataFaqThemeBlock>(
    themeBlocks[0] ? themeBlocks[0].content : null
  )
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentFaq, setCurrentFaq] = useState<DataFaqItem[]>([])
  const pageTitle = faqPage?.metaTitle
    ? faqPage?.metaTitle
    : faqPage?.title
    ? faqPage?.title.replace(/\n/g, '')
    : 'FAQ'

  const pagePopinGeneralVisible = faqPage?.popinGeneralVisible

  const handleChangeTag = (e: React.MouseEvent, index: number) => {
    e.preventDefault()

    setCurrentData(themeBlocks[index].content)
    setCurrentIndex(index)
  }

  useEffect(() => {
    setCurrentFaq(
      themeBlocks.reduce((acc: DataFaqItem[], item: { content: DataFaq }) => {
        const questions = item.content.questions || []
        acc = [...acc, ...questions]

        return acc
      }, [])
    )
    window.scrollTo(0, 0)

    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
    }
  }, [])

  return (
    <>
      <Layout canDisplayPopin={pagePopinGeneralVisible}>
        <Helmet>
          <body className="header-green"></body>
        </Helmet>
        {themeBlocks && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: [
                  themeBlocks.map((themeBlock) => (
                    themeBlock.content.questions && (
                      themeBlock.content.questions.map((faq) => ({
                          '@type': 'Question',
                          name: faq.question,
                          acceptedAnswer: {
                            '@type': 'Answer',
                            text: stripeHtmlTag(faq.answer),
                          }
                        }
                      ))
                    )
                  ))
                ],
              }),
            }}
          ></script>
        )}
        <CmsHeader
          bg={faqPage.backgroundColor}
          title={faqPage.title}
          description={faqPage.subtitle}
        />
        <div
          className="overflow-hidden"
          style={
            {
              '--page-bg': `#${faqPage.backgroundColor}`,
            } as React.CSSProperties
          }
        >
          <div className="container">
            <div className={styles.faqHead}>
              <Slider
                pagination={false}
                navigation={false}
                slidesPerView="auto"
                breakpoints={{
                  768: {
                    enabled: false,
                  },
                }}
                spaceBetween={16}
                data={
                  themeBlocks && themeBlocks
                    ? themeBlocks.map(
                        (
                          themeBlock: { content: DataFaqThemeBlock },
                          index: number
                        ) => ({
                          content: (
                            <button
                              key={`i-${index}`}
                              aria-label={themeBlock.content.title}
                              onClick={(e) => handleChangeTag(e, index)}
                              className={
                                index === currentIndex ? styles.active : ''
                              }
                            >
                              {/* {icon[themeBlock.content.picto]} */}
                              {themeBlock.content.pictoImage && (
                                <img
                                  src={themeBlock.content.pictoImage.src}
                                  alt={themeBlock.content.title}
                                  className={styles.tabPicto}
                                />
                              )}
                              <Text
                                tag="span"
                                as="h6"
                                className="tab-title font-bold"
                              >
                                {themeBlock.content.title}
                              </Text>
                            </button>
                          ),
                        })
                      )
                    : []
                }
              />
            </div>
            {currentData && (
              <FaqContent data={currentData} index={currentIndex} />
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query FAQPageQuery($lang: String!) {
    allUmbracoFaqPage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        name
        title
        subtitle
        backgroundColor
        metaTitle
        metaDescription
        metaFollow
        metaIndex
        twitterCard {
          id
          url
        }
        facebookCard {
          id
          url
        }
        _url
        _urls {
          fr
          en_us
        }
        popinGeneralVisible
        themeBlocks {
          content {
            title
            picto
            pictoImage {
              src
            }
            questions {
              name
              answer
              question
            }
          }
        }
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
